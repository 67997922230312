import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadPdf, fetchPdfList } from "./adminActions";
import { changePassword } from "../auth/authActions";
import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import BookListAdmin from "./BookListAdmin";
import { Form, Button, Spinner, Modal } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

//change password modal
const ChangePasswordModal = (props) => {
  const isLoading = useSelector((state) => state.auth.loading);
  const dispatch = useDispatch();
  const [passwords, setPasswords] = useState({
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState("");

  const { onHide } = props;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prevPasswords) => ({
      ...prevPasswords,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("here");
    if (passwords.password !== passwords.confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    setError("");
    console.log("Here CHanging Password");
    dispatch(changePassword({ password: passwords.password }))
      .unwrap()
      .then((data) => {
        console.log(data);
        toast.success(data.message, {
          position: "top-right",
          autoClose: 3000,
        });

        onHide();
        setPasswords({ password: "", confirmPassword: "" });
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
        });
      });
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Change Password
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={passwords.password}
              onChange={handleChange}
              required
              autoFocus
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              name="confirmPassword"
              value={passwords.confirmPassword}
              onChange={handleChange}
              required
            />
          </Form.Group>
          {error && <div className="text-danger">{error}</div>}
          <Button type="submit" >
            {isLoading ? (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  Loading...
                </>
            ) : (
                'Change Password'
            )}
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

const AdminDashboard = () => {
  const [title, setTitle] = useState("");
  const [file, setFile] = useState(null);
  const [modalShow, setModalShow] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isUploading,setIsUploading] = useState(false)

  const handlePasswordChangeSuccess = () => {
    setModalShow(false); // Close the modal when password change is successful
  };

  const handleUpload = (e) => {
    e.preventDefault();
    setIsUploading(true);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("file", file);
    dispatch(uploadPdf(formData)) // Dispatch action to upload PDF
      .unwrap()
      .then((data) => {
        toast.success(data.message, {
          position: "top-right",
          autoClose: 3000,
        });

        dispatch(fetchPdfList()) // Dispatch the fetchPdfList action
          .unwrap()
          .catch((error) => {
            // Handle any errors here
            console.error("Error fetching PDF list:", error);
            toast.error(error, {
              position: "top-right",
              autoClose: 3000, // Close the notification after 3 seconds
            });
            navigate("/admin");
          });
        // console.log("here");
        // navigate("/admin/dashboard"); // to load latest added books
        // console.log("Navigated to Admin Dashboard");
      })
      .catch((error) => {
        // const messageLines = error.split('\n');
        // Handle any errors here
        toast.error(error, {
          position: "top-right",
          autoClose: 7000, // Close the notification after 3 seconds
        });
      }).finally(() => {
      setIsUploading(false); // Set isUploading to false after the operation completes
    });

  };

  return (
    <>
      <ChangePasswordModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <Header />
      <main className="container my-4">
        <div className="d-flex flex-row justify-content-between">
          <h2>Admin Dashboard</h2>
          <a
            class="btn btn-secondary"
            href="#"
            role="button"
            onClick={() => setModalShow(true)}
          >
            Change Password
          </a>
        </div>

        <br />
        <div>
          <h5>Upload New PDF</h5>
          <form onSubmit={handleUpload}>
            <input
              type="text"
              placeholder="Title of the book"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <br />
            <br />
            <input
              name="file"
              type="file"
              onChange={(e) => setFile(e.target.files[0])}
            />
            <Button variant="primary" type="submit">
              {isUploading ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    Uploading....
                  </>
              ) : (
                  'Upload'
              )}
            </Button>
          </form>
        </div>
        <hr />
        <BookListAdmin />
      </main>
      <Footer />
    </>
  );
};

export default AdminDashboard;
