import React from 'react';

const Header = () => {
    return (
        <header className="header-bg">
            <div className="header-text">
                <h1>Bhajera Se. Sec. School</h1>
                {/* className="bg-light py-3" Additional header content */}
            </div>
        </header>
    );
};

export default Header;
