import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../../config/constants";
import axios from "axios";

export const login = createAsyncThunk(
  "auth/login",
  async (userData, { rejectWithValue }) => {
    try {
      // Make a POST request to the login API endpoint using Axios
      const response = await axios.post(`${API_BASE_URL}/login`, userData);
      // console.log(response);
      const responseData = response.data;
      // const dispatch = useDispatch();
      // dispatch(setToken(responseData.token));
      // console.log('setting token...', responseData.token)
      // Return the response data (e.g., user information) for a successful login
      return responseData;
    } catch (error) {
      if (error.response?.data) {
        // Update the error state to display the error message to the user
        return rejectWithValue(error.response.data.message);
      } else {
        // Update the error state to display the error message to the user
        return rejectWithValue(error.message);
      }
    }
  }
);

export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (data, { rejectWithValue }) => {
    try {
      const jwtToken = localStorage.getItem("token");
      // Create headers with the Bearer token
      const headers = {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      };
      const response = await axios.post(
        `${API_BASE_URL}/admin/change-password`,
        data,
        {
          headers: headers,
        }
      );
      const responseData = response.data;
      return responseData;
    } catch (error) {
      if (error.response?.data) {
        // Update the error state to display the error message to the user
        return rejectWithValue(error.response.data.message);
      } else {
        // Update the error state to display the error message to the user
        return rejectWithValue(error.message);
      }
    }
  }
);
