import { configureStore } from '@reduxjs/toolkit';

// Import slices
import booksSlice from '../features/books/booksSlice'; // Replace with your actual slice
import authSlice from '../features/auth/authSlice'; // Replace with your actual slice

export const store = configureStore({
  reducer: {
    books: booksSlice,
    auth: authSlice,
  },
  // The middleware is automatically added by configureStore
});

export default store;
