import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot from react-dom/client
import { Provider } from 'react-redux';
import store from './app/store'; // Adjust path as necessary
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css'; // If you're using Bootstrap

const root = createRoot(document.getElementById('root')); // Create a root

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
