import React from 'react';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';

const NotFoundPage = () => {
    return (
        <>
            <Header />
            <main className="container my-4">
                <div className="not-found-content">
                    <h1>404 - Page Not Found</h1>
                    <p>Sorry, the page you are looking for does not exist.</p>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default NotFoundPage;
