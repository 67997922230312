import React from 'react';

const Pagination = ({ booksPerPage, totalBooks, paginate, currentPage }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalBooks / booksPerPage); i++) {
        pageNumbers.push(i);
    }


    return (
        <nav>
            <ul className="pagination">
                <li className="page-item"><a className="page-link" href="#" onClick={() => paginate(1)}>First</a></li>
                {currentPage>1 && <li className="page-item"><a className="page-link" href="#" onClick={() => paginate(currentPage-1)}>Previous</a></li> }
                {pageNumbers.map(number => (
                    <li key={number} className={`page-item ${currentPage === number? "active" : "" }`}>
                        <a onClick={() => paginate(number)} href="#" className="page-link">
                            {number}
                        </a>
                    </li>
                ))}
                {currentPage < pageNumbers.length && <li className="page-item"><a className="page-link" href="#" onClick={() => paginate(currentPage+1)}>Next</a></li> }
                <li className="page-item"><a className="page-link" href="#" onClick={() => paginate(pageNumbers.length)}>Last</a></li>
            </ul>
        </nav>
    );
};

export default Pagination;
