import React from 'react';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import BooksList from './BooksList';

const HomePage = () => {
    return (
        <>
            <Header />
            <main className="container my-4">
                <BooksList />
            </main>
            <Footer />
        </>
    );
};

export default HomePage;
