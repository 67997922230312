import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPdfList, deleteBook } from "./adminActions";
import Pagination from "../../components/common/Pagination";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../config/constants";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

const BooksPerPage = 20;

const BookListAdmin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pdfList = useSelector((state) => state.books.pdfs);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastBook = currentPage * BooksPerPage;
  const indexOfFirstBook = indexOfLastBook - BooksPerPage;

  const currentBooks = pdfList.slice(indexOfFirstBook, indexOfLastBook);

  // Check if pdfList is an array and not empty
  const handleDelete = (bookId) => {
    if (window.confirm("Are you sure you want to delete this book?")) {
      dispatch(deleteBook(bookId)) // Dispatch action to delete the book
        .unwrap()
        .then((data) => {
          toast.success(data.message, {
            position: "top-right",
            autoClose: 3000, // Close the notification after 3 seconds
          });
          dispatch(fetchPdfList()) // Dispatch the fetchPdfList action
            .unwrap()
            .catch((error) => {
              // Handle any errors here
              console.error("Error fetching PDF list:", error);
              toast.error(error, {
                position: "top-right",
                autoClose: 3000, // Close the notification after 3 seconds
              });
              navigate("/admin");
            });
        })
        .catch((error) => {
          // Handle any errors here
          console.error("Error in deleting PDF list:", error);
          toast.error(error, {
            position: "top-right",
            autoClose: 7000, // Close the notification after 3 seconds
          });
        });
    }
  };

  const handleView = (bookId) => {
    // Assuming 'book' contains the necessary information, including the PDF URL
    const pdfUrl = `${API_BASE_URL}/download-pdf/${bookId}`;

    // Create an anchor element for the PDF link
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.target = "_blank"; // Open the PDF in a new tab
    link.rel = "noopener noreferrer"; // For security reasons
    link.download = ""; // Remove the 'download' attribute to prevent forced download

    // Trigger a click event on the anchor element to open the PDF
    link.click();
  };

  // console.log("......pdfList.....", pdfList);

  useEffect(() => {
    dispatch(fetchPdfList()) // Dispatch the fetchPdfList action
      .unwrap()
      .catch((error) => {
        // Handle any errors here
        console.error("Error fetching PDF list:", error);
        toast.error(error, {
          position: "top-right",
          autoClose: 3000, // Close the notification after 3 seconds
        });
        navigate("/admin");
      });
  }, [dispatch]);

  return (
    <div>
      <h5>Available PDF Books</h5>
      {pdfList.length > 0 ? (
        <>
          <table className="table">
            <thead>
              <tr>
                <th>Title</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentBooks.map((book) => (
                <tr key={book.id}>
                  <td>{book.title}</td>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => handleView(book.id)}
                    >
                      View
                    </button>{" "}
                    &nbsp;
                    <button
                      className="btn btn-danger"
                      onClick={() => handleDelete(book.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            booksPerPage={BooksPerPage}
            totalBooks={pdfList.length}
            paginate={setCurrentPage}
            currentPage = {currentPage}
          />
        </>
      ) : (
        <p>No books are uploaded till now.</p>
      )}
    </div>
  );
};

const MemoizedBookListAdmin = React.memo(BookListAdmin);

export default MemoizedBookListAdmin;
