import React, {useEffect} from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes, useNavigate} from 'react-router-dom';
import HomePage from './features/books/HomePage';
import AdminDashboard from './features/admin/AdminDashboard';
import LoginPage from './features/auth/LoginPage';
import NotFoundPage from './features/404/NotFoundPage';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useSelector} from "react-redux";


const App = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  return (
    <>
    <ToastContainer />
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        {isAuthenticated ? (
            <Route path="/admin/dashboard" element={<AdminDashboard />} />
        ) : (
            <Route path="/admin/*" element={<LoginPage />} />
        )}
        {/* Other routes */}
        <Route path="/admin/*" element={<LoginPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
    </>
  );
};

export default App;
