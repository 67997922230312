import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBooks } from "./booksActions";
import Pagination from "../../components/common/Pagination";
import { API_BASE_URL } from "../../config/constants";

const BooksPerPage = 18;

const BooksList = () => {
  const dispatch = useDispatch();
  // Update the selector according to your state structure
  // For example, if books is in a nested object like state.books.data
  const books = useSelector((state) => state.books.books || []);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(fetchBooks());
  }, [dispatch]);

  const handleDownload = (e, bookId) => {
    e.preventDefault();
    // Assuming 'book' contains the necessary information, including the PDF URL
    const pdfUrl = `${API_BASE_URL}/download-pdf/${bookId}`;

    // Create an anchor element for the PDF link
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.target = "_blank"; // Open the PDF in a new tab
    link.rel = "noopener noreferrer"; // For security reasons
    link.download = ""; // Remove the 'download' attribute to prevent forced download

    // Trigger a click event on the anchor element to open the PDF
    link.click();
  };

  const indexOfLastBook = currentPage * BooksPerPage;
  const indexOfFirstBook = indexOfLastBook - BooksPerPage;

  const currentBooks = books.slice(indexOfFirstBook, indexOfLastBook);

  return (
    <div>
      <h2>Available PDF Books</h2>
      {books.length > 0 ? (
        <div>
          <div className="row">
            {currentBooks.map((book) => (
              <div className="col-md-2" key={book.id} style={{marginBottom: "10px"}}>
                <div className="card" style={{ width: "1 rem" }}>
                  <img
                    src="/pdf-logo.svg"
                    className="card-img-top pdf-logo"
                    alt="pdf-logo"
                  />
                  <div className="card-body ">
                    <h5 className="card-title no-overflow-card-title">{book.title}</h5>

                    <a
                      href={book.pdfUrl}
                      onClick={(event) => handleDownload(event, book.id)}
                      className="btn btn-primary"
                      style={{ width: "100%", display: "block" }}
                      download
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <hr />
          <Pagination
            booksPerPage={BooksPerPage}
            totalBooks={books.length}
            paginate={setCurrentPage}
            currentPage = {currentPage}
          />
        </div>
      ) : (
        <p>No books are uploaded till now.</p>
      )}
    </div>
  );
};

export default BooksList;
