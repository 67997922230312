import { createSlice } from '@reduxjs/toolkit';

export const booksSlice = createSlice({
  name: 'books',
  initialState: {
    books: [],
    pdfs: [],
  },
  reducers: {
    // Define your reducers here
    // addBook: (state, action) => {
    //   state.books.push(action.payload);
    // },
    setPdfs(state, action) {
      state.pdfs = action.payload;
    },
    setBooks(state, action) {
      state.books = action.payload;
    }
  },
});

// Export actions
export const { setPdfs, setBooks } = booksSlice.actions;

export default booksSlice.reducer;
