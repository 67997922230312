import { createAsyncThunk } from '@reduxjs/toolkit';
import { API_BASE_URL } from '../../config/constants';
import axios from 'axios';
import {setBooks} from '../books/booksSlice';

// Async action for fetching the list of books
export const fetchBooks = createAsyncThunk(
    'books/fetchBooks',
    async (_, { rejectWithValue, dispatch }) => {
        try {
            // Use Axios for the GET request
            const response = await axios.get(`${API_BASE_URL}/pdf-list`);

            if (response.status !== 200) {
                throw new Error('Failed to fetch books');
            }
            // console.log('in books action....',response.data)
            const pdfs = response.data;

            dispatch(setBooks(pdfs.data));

            return pdfs.data;
        } catch (error) {
            if (error.response?.data) {
                // Update the error state to display the error message to the user
                return rejectWithValue(error.response.data.message);
            } else {
                // Update the error state to display the error message to the user
                return rejectWithValue(error.message);
            }
        }
    }
);