// Importing necessary redux toolkit functions
import { createAsyncThunk, rejectWithValue } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../../config/constants";
import { setPdfs } from "../books/booksSlice";
import axios from "axios";

export const fetchPdfList = createAsyncThunk(
  "admin/fetchPdfList",
  async (_, { rejectWithValue, dispatch }) => {
    try {

      const jwtToken = localStorage.getItem("token");
      // Create headers with the Bearer token
      const headers = {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json", // Adjust content type if needed
      };

      // Use Axios to make the GET request
      const response = await axios.get(`${API_BASE_URL}/admin/pdf-list`, {
        headers: headers,
      });


      if (response.status === 401){
        window.location.href = '/admin';
      }
      const pdfs = response.data;
      // Dispatch the setPdfs action with the data to update the state
      dispatch(setPdfs(pdfs.data));

      return pdfs.data;
    } catch (error) {
      if (error.response?.data) {
        // Update the error state to display the error message to the user
        return rejectWithValue(error.response.data.message);
      } else {
        // Update the error state to display the error message to the user
        return rejectWithValue(error.message);
      }
    }
  }
);

function formatErrorMessage(errorData) {
  let errorMessage = errorData.message + " due to validation of fields.\n";
  let index = 1;
  for (const fieldName in errorData.data) {
    if (errorData.data.hasOwnProperty(fieldName)) {
      const fieldErrors = errorData.data[fieldName];
      errorMessage += `${index}. ${fieldName}: ${fieldErrors.join(", ")}\n`;
    }
    index++;
  }

  return errorMessage.trim(); // Remove trailing newline
}

export const uploadPdf = createAsyncThunk(
  "admin/uploadPdf",
  async (pdfData, { rejectWithValue, getState }) => {
    try {
      const jwtToken = localStorage.getItem("token");

      // Create headers with the Bearer token and content type
      const headers = {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "multipart/form-data", // Set the content type to form data
      };

      const response = await axios.post(
        `${API_BASE_URL}/admin/upload-pdf`,
        pdfData,
        {
          headers: headers,
        }
      );

      if (response.status ===401){
        window.location.href = '/admin';
      }
      if (response.status !== 201) {
        throw new Error("Server error!");
      }
      const data = response.data;
      return data;
    } catch (error) {
      if (error.response?.data) {
        // Update the error state to display the error message to the user
        if (error.response.data?.data) {
          let message = formatErrorMessage(error.response.data);
          console.log("message......", message);
          return rejectWithValue(message);
        }
        return rejectWithValue(error.response.data.message);
      } else {
        // Update the error state to display the error message to the user
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteBook = createAsyncThunk(
  "admin/deleteBook",
  async (bookId, { rejectWithValue, dispatch }) => {
    try {
      const jwtToken = localStorage.getItem("token");
      // Create headers with the Bearer token
      const headers = {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      };

      // Use Axios to make the DELETE request
      const response = await axios.delete(
        `${API_BASE_URL}/admin/delete-pdf/${bookId}`,
        {
          headers: headers,
        }
      );
      if (response.status ===401){
        window.location.href = '/admin';
      }

      if (response.status === 200) {
        // Fetch the updated list of books and dispatch the setBooks action
        // const booksResponse = await axios.get(`${API_BASE_URL}/admin/pdf-list`, {
        //     headers: headers,
        // });
        // const booksData = booksResponse.data;
        // dispatch(setPdfs(booksData));
      }

      return response.data;
    } catch (error) {
      if (error.response?.data) {
        // Update the error state to display the error message to the user
        return rejectWithValue(error.response.data.message);
      } else {
        // Update the error state to display the error message to the user
        return rejectWithValue(error.message);
      }
    }
  }
);
