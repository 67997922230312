import React from 'react';
import './Footer.css';

const Footer = () => {
    const currentYear = new Date().getFullYear(); // Gets the current year

    return (
        <footer className="footer">
            <div className="container">
                <p className="footer-text">
                    © {currentYear} Bhajera School. All rights reserved.
                </p>
            </div>
        </footer>
    );
};

export default Footer;
