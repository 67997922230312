import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from './authActions';
import { Form, Button, Spinner } from 'react-bootstrap';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import './LoginPage.css';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Use useSelector to access loading state from Redux
    const isLoading = useSelector((state) => state.auth.loading);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(login({ username, password }))
            .unwrap()
            .then((result) => {
                // Handle successful login
                localStorage.setItem('token', result.token);
                navigate('/admin/dashboard');
            })
            .catch((error) => {
                // console.log('error in login page.....', error)
                toast.error(error, {
                    position: 'top-right',
                    autoClose: 3000, // Close the notification after 3 seconds
                });
            });
    };


    return (
        <div className="login-page">
            <Header />
            <div className="login-form-container">
                <Form onSubmit={handleSubmit} className='login-form'>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Username</Form.Label>
                        <Form.Control type="text" placeholder="Enter username" onChange={(e) => setUsername(e.target.value)} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        {isLoading ? (
                            <>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                Loading...
                            </>
                        ) : (
                            'Sign-in'
                        )}
                    </Button>
                </Form>
            </div>
            <Footer />
        </div>
    );
}

export default LoginPage;
