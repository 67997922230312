import { createSlice } from "@reduxjs/toolkit";
import { login, changePassword } from "./authActions";

const initialState = {
  isAuthenticated: false,
  user: null,
  loading: false, // Add loading state
  error: null, // Add error state
  token: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginUser: (state, action) => {
      // reducer to handle a login action
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    // Add reducer to handle logout action
    logoutUser: (state) => {
      state.isAuthenticated = false;
      state.user = null;
    },
    // Add reducer to handle error action
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    // Add reducer to reset error state
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // Add async thunk reducers for login action
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.user = null;
        state.error = action.payload;
        state.loading = false;
      });

    // Add async thunk reducers for password change action
    builder
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { loginUser, setToken, logoutUser, setError, clearError } =
  authSlice.actions;

export default authSlice.reducer;
